import {facilitySearchService} from "@/services/facility/search/search.service";
import PaginateComponent from "@/resources/components/paginate.component";
import {alertService} from "@/services/alert/alert.service";
import { reservation_status } from "@/utils/mockup";

export default {
    components: {PaginateComponent},
    data() {
        return {
            data: {},
            dataReady: false,
            filter: {
                paginate : 1,
                per_page: 10,
                entry_date: '',
                entry_time: '',
                exit_date: '',
                exit_time: '',
                region: '',
                sort_number: '',
                hiragana: '',
                registered_number: '',
                phone_number: '',
                name: '',
                status: '',
                flight_status: "cancelled"
            },
            reservation_status: reservation_status,
        }
    },
    async mounted() {
        this.list(this.filter, {page: 1});
    },
    methods: {
        async search$(data) {
            this.list(this.filter, data)
        },

        async list(query, data) {
            this.dataReady = false
            alertService.loading();
            await facilitySearchService.list({...query, ...data, ...{flight_use: 1}}).then((response) => {
                this.data = response.data
                this.dataReady = true
                alertService.close();
            }).catch((error) => {
                alertService.close();
                console.log(error)
            });

        },

        clear$() {
            this.filter = {
                paginate : 1,
                per_page: 10,
                entry_date: '',
                entry_time: '',
                exit_date: '',
                exit_time: '',
                region: '',
                sort_number: '',
                hiragana: '',
                registered_number: '',
                phone_number: '',
                name: '',
                status: '',
                flight_status: "cancelled"
            }

            this.list(this.filter, {page: 1})
        },

        searchByStatus$(status) {
            this.filter.status = status
            this.search$()

        }
    }

}