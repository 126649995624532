import api from '../../../globals/client';
import config from '../../../config/app.config';

var promise;

export default class FacilitySearchService {
    async list(data) {
        promise = await api.client.get(config.end_point.facility.search, {params: data});
        return promise;
    }
}

export const facilitySearchService = new FacilitySearchService();
